import React from 'react';
import {Link, useLocation} from "react-router-dom";

function Header() {
    const {pathname} = useLocation();

    return (
        <header className="header">
            <div className="w-layout-vflex header-left">
                <Link to="/" aria-current="page" className="header-a w-inline-block w--current">
                    <h1 className="header-h1">MetaDesigners<span className="logo-span">.dev</span></h1>
                </Link>
                <p className="header-p color-secondary">Web3-студия</p>
            </div>
            <div className="w-layout-hflex header-right">
                <p className="header-p color-secondary p-small">
                    <Link to="/projects" className={!pathname.includes('projects') ? '' : 'active'}>Все кейсы</Link><span className=""> · </span>
                    <a href="https://calendly.com/metadesignersdev/buidl" target="_blank" rel="noreferrer">Назначить встречу</a><span
                    className="dot"> ·</span>
                </p>
                <a href="https://t.me/metadesignersdev" target="_blank" className="button w-button" rel="noreferrer">Обсудить проект</a>
            </div>
        </header>
    );
}

export default Header;
