import React from 'react';

function Referals() {
    return (
        <div className="card">
            <div className="card-content">
                <h2>Реферальная программа</h2>
                <p>Предложите проект и станьте партнером студии — условия оговариваем индивидуально</p>
                <a href="https://t.me/metadesignersdev" target="_blank" className="button button-small w-button" rel="noreferrer">Предложить проект</a>
            </div>
        </div>
    );
}

export default Referals;
