import React, {useEffect} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import {ContentTypes, StrapiResponse} from "./interfaces";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {getData} from "./hooks/api";
import {setProjects} from "./reducers/projectsSlice";
import ScrollToTop from "./components/ScrollToTop";

function Layout() {
    const projects = useAppSelector(state => state.projects.value)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(projects?.length === 0){
            const query = {
                sort: ['updatedAt:desc'],
                populate: "*",
            }

            getData({contentType: ContentTypes.PROJECTS, query: query}).then((response: StrapiResponse) => {
                dispatch(setProjects(response?.data))
            })
        }
    }, [projects])

    return (
        <Router>
            <ScrollToTop />
            <Header/>
            <main className="content">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/projects/" element={<Projects/>}/>
                    <Route path="/project/:projectSlug" element={<Project/>}/>
                </Routes>
            </main>
            <Footer/>
        </Router>
    );
}

export default Layout;
