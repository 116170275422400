import React from "react";
import {Project, Service} from "../../interfaces";
import {Link} from "react-router-dom";

function ProjectCard({project, className, isProjectPage}: {project: Project, className?: string, isProjectPage?: boolean}) {
    const services = project.attributes.Services.data.map((s:Service) => s.attributes.Title)

    return (
        <div className={className ? `card ${className}` : `card`}>
            <div className="card-content pb-0">
                <h2>{project.attributes.Title}</h2>
                <div className="card-content-p-short" dangerouslySetInnerHTML={{ __html: project.attributes.Description }} />
                <div className="w-layout-hflex card-content-meta">
                    <p className="card-content-services p-small">{services.join(" · ")}</p>
                    <p className="p-small">
                        {!isProjectPage ? <Link to={`/project/${project.attributes.Slug}`} className="card-content-link">Посмотреть кейс →</Link> :
                            (project.attributes.Website ? <a href={project.attributes.Website} target="_blank" className="card-content-link" rel="noreferrer">Посмотреть сайт →</a> : "Клиент предпочел скрыть адрес сайта")
                        }
                    </p>
                </div>
                <img src={project.attributes.Thumbnail.data.attributes.url} loading="lazy" alt="" className="card-image"/>
            </div>
            {!isProjectPage && <Link to={`/project/${project.attributes.Slug}`} className="card-fake-link w-inline-block"></Link>}
        </div>
    );
}
export default ProjectCard;