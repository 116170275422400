import {useEffect, useState} from "react";
import {getData} from "./api";
import {Project, StrapiResponse} from "../interfaces";

export const useAPI = (contentType: string, query?: object) => {
    const [posts, setPosts] = useState<Project[]>();

    useEffect(() => {
        if(!posts){
            getData({contentType: contentType, query: query}).then((response: StrapiResponse) => {
                setPosts(response?.data)
            })
        }
    }, [posts, contentType, query])

    if(!posts){
        return {
            loading: true,
        };
    }

    return {
        loading: false,
        data: posts
    }
}