import React from 'react';
import ProjectCard from "../components/project/ProjectCard";
import {Project} from "../interfaces";
import {useAppSelector} from "../hooks/redux";
import SetHeader from "../components/SetHeader";

function Projects() {
    const projects = useAppSelector(state => state.projects.value)

    return (
        <div className="projects">
            <SetHeader title="Посмотреть проекты" />
            {projects?.length === 0 ? <div className="loading">Загружаем все сочные проекты...</div> : <>
                {projects && projects.map((project: Project) => {
                    return <ProjectCard project={project} key={project.id}/>
                })}
            </>}
        </div>
    );
}

export default Projects;
