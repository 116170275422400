import {createSlice} from "@reduxjs/toolkit";
import {Project} from "../interfaces";
import {RootState} from "../store";

const initialState = {
    value: [],
    state: 'idle'
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const selectProjects = (state: RootState) => state.projects.value;
export const selectProject = (state: RootState, slug: string | undefined) : Project | undefined => {
    const project = state.projects?.value?.find((item:Project) => {
        return item.attributes.Slug === slug;
    })
    return project;
}

export const { setProjects } = projectsSlice.actions;

export default projectsSlice.reducer;
