import React from 'react';

function Team() {
    return (
        <div className="card">
            <div className="card-content">
                <h2>Команда</h2>
                <p>Ценим и любим каждого</p>
                <div className="w-layout-hflex members">
                    <div className="w-layout-vflex member">
                        <img src="/images/team/ceo.svg" loading="lazy" alt="" className="member-img"/>
                        <p className="p-small"><strong>Никита</strong><br/>Управление и продуктовый дизайн</p>
                    </div>
                    <div className="w-layout-vflex member">
                        <img src="/images/team/cdo.svg" loading="lazy" alt="" className="member-img"/>
                        <p className="p-small"><strong>Майк</strong><br/>Коммуникационный дизайн <br/>и маркетинг</p>
                    </div>
                    <div className="w-layout-vflex member">
                        <img src="/images/team/devs.svg" loading="lazy" alt="" className="member-img"/>
                        <p className="p-small">Команда дизайна и разработки</p>
                    </div>
                </div>
                <a href="https://t.me/metadesignersdev" target="_blank" className="button button-small w-button" rel="noreferrer">Стать частью команды</a>
            </div>
        </div>
    );
}

export default Team;
