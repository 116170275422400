import {configureStore} from "@reduxjs/toolkit";
import projectsSlice from "./reducers/projectsSlice";
import { combineReducers } from 'redux';

const store = configureStore({
    reducer: combineReducers({
        projects: projectsSlice,
    })
})
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch