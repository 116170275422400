import React from 'react';
import {useAPI} from "../../hooks/useAPI";
import {ContentTypes, TechStack} from "../../interfaces";

function TechStacks() {
    const query = {
        sort: ['createdAt:asc'],
        populate: "*"
    }
    const {loading, data: stacks} = useAPI(ContentTypes.TECHSTACKS, query);

    return (
        loading ? <>Генерируем байткод...</> :
            <div className="card">
                <div className="card-content">
                    <h2>Используемые технологии</h2>
                    <div className="w-layout-hflex stacks">
                            {stacks && stacks.map((stack: TechStack) => {
                                return <div className="w-layout-vflex stack" key={stack.id}>
                                    <img src={stack.attributes.Thumbnail.data.attributes.url} loading="lazy" alt="" className="stack-img"/>
                                    <p className="p-small"><strong>{stack.attributes.Title}</strong></p>
                                </div>
                            })}
                    </div>
                </div>
            </div>
    );
}

export default TechStacks;
