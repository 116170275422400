import React from 'react';
import {Link} from "react-router-dom";
import ProjectCard from "../components/project/ProjectCard";
import {useAppSelector} from "../hooks/redux";
import {selectProjects} from "../reducers/projectsSlice";
import SetHeader from "../components/SetHeader";

function Home() {
    const projects = useAppSelector(state => selectProjects(state))

    return (
        <div className="projects">
            <SetHeader title="web3-студия" />
            {projects?.length === 0 ? <div className="loading">Загружаем самые сочные проекты...</div> : <>
                {projects?.length > 0 && <>
                    {projects[0] && <ProjectCard project={projects[0]} className="hero-project"/>}
                    {projects[1] && <ProjectCard project={projects[1]} className="back-project right"/>}
                    {projects[2] && <ProjectCard project={projects[2]} className="back-project left"/>}
                </>}
            </>}
            {projects?.length > 0 && <Link to="/projects" className="button button-projects w-button">Посмотреть все кейсы</Link>}
          </div>
    );
}

export default Home;
