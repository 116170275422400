import React from 'react';
import {useParams} from "react-router-dom";
import ProjectCard from "../components/project/ProjectCard";
import {useAppSelector} from "../hooks/redux";
import {selectProject} from "../reducers/projectsSlice";
import SetHeader from "../components/SetHeader";

function Project() {
    const {projectSlug} = useParams();
    const project = useAppSelector(state => selectProject(state, projectSlug))

    return (
        <div className="projects">
            {!project ? <div className="loading">Загружаем сочное описание...</div> :
                (project && <>
                    <SetHeader title={project.attributes.Title} />
                    <ProjectCard project={project} isProjectPage={true} className="hero-project"/>
                    <div className="card project-content">
                        <div className="card-content" dangerouslySetInnerHTML={{ __html: project?.attributes?.Content }}></div>
                    </div>
                    <a href="https://t.me/metadesignersdev" target="_blank" className="button button-projects w-button" rel="noreferrer">Обсудить наш будущий проект</a>
                </>)
            }
        </div>
    );
}

export default Project;
