import React from 'react';
import Services from "./footer/Services";
import Referals from "./footer/Referals";
import Team from "./footer/Team";
import TechStacks from "./footer/TechStacks";

function Footer() {
  return (
      <>
          <div className="cards-static">
              <div id="w-node-afc9224e-f0c8-9951-cb1f-2ffc6413d436-9181a858" className="w-layout-vflex cards-static-col">
                  <Services />
                  <Referals />
              </div>
              <div id="w-node-afc9224e-f0c8-9951-cb1f-2ffc6413d460-9181a858" className="w-layout-vflex cards-static-col">
                  <Team />
                  <TechStacks />
              </div>
          </div>
          <footer className="footer">
              <p className="header-p color-secondary p-small">2024 · <a href="mailto:buidl@metadesigners.dev">buidl@metadesigners.dev</a> · <a href="https://calendly.com/metadesignersdev/buidl" target="_blank" rel="noreferrer">Назначить встречу</a>
              </p>
          </footer>
      </>
  );
}

export default Footer;
