import React from 'react';
import {Link} from "react-router-dom";

function Services() {
    return (
        <div className="card">
            <div className="card-content">
                <h2>Услуги</h2>
                <p>Работаем по agile, делаем всё возможное, чтобы вы были довольны результатом.</p>
                {/*<p>Оплата возможна в USDT и рублях по текущему курсу: 50% предоплата и 50% постоплата после выполнения работ, если иное не отражено в договоре. </p>*/}
                <ul className="services-list p-small w-list-unstyled">
                    <li>
                        <p className="services-list-li">Аналитика и архитектура</p>
                    </li>
                    <li>
                        <p className="services-list-li">Исследования</p>
                    </li>
                    <li>
                        <p className="services-list-li">Дизайн веб и мобильных приложений</p>
                    </li>
                    <li>
                        <p className="services-list-li">Верстка в коде и no-code</p>
                    </li>
                    <li>
                        <p className="services-list-li">Разработка фронтенда и бекенда</p>
                    </li>
                    <li>
                        <p className="services-list-li">Разработка Solidity-контрактов</p>
                    </li>
                    <li>
                        <p className="services-list-li">NFT-коллекции</p>
                    </li>
                    <li>
                        <p className="services-list-li">Телеграм-боты, скрипты, парсеры</p>
                    </li>
                    <li>
                        <p className="services-list-li">Разворачивание и поддержка серверов</p>
                    </li>
                    <li>
                        <p className="services-list-li">И другое по запросу клиента</p>
                    </li>
                </ul>
                <Link to="/projects" className="button button-small w-button">Посмотреть реализованные проекты</Link>
            </div>
        </div>
    );
}

export default Services;
