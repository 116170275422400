import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SetHeader({title}: {title: string}) {
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = `Metadesigners.dev — ${title}`;
    }, [pathname, title]);

    return null;
}