import axios from "axios";
import qs from "qs";

export async function getData({contentType, query} : {contentType: string, query?: object}) {
    const stringifiedQuery = qs.stringify(query)
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_STRAPI_URL}/api/${contentType}?${stringifiedQuery}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
                },
            }
        )
        return response.data
    }catch (e){
        return null
    }
}