
export interface TechStack {
    id: number;
    attributes: {
        Title: string;
        Description: string;
        Thumbnail: Media;
    }
}

export interface Service {
    id: number;
    attributes: {
        Title: string;
        Description: string;
        Price: string;
    }
}
export interface Media {
    data: {
        id: number;
        attributes: {
            url: string;
            mime: string;
        }
    }
}

export interface Project {
    id: number;
    attributes: {
        Title: string;
        Description: string;
        Thumbnail: Media;
        Content: string;
        Website: string;
        Services: {
            data: Service[]
        }
        Slug: string;
        publishedAt: string;
    }
}

export interface StrapiResponse {
    data: Project[];
    meta: {
        pagination: {
            page: number;
            pageCount: number;
            pageSize: number;
            total: number;
        }
    }
}


export enum ContentTypes {
    PROJECTS="projects",
    TECHSTACKS="tech-stacks"
}